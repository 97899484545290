<template>
  <div class="holder">
    <h1>tickNum: 几层多边行(Number) &nbsp; 默认值：5</h1>
    <h1>lineColors: 线的颜色(Array) &nbsp; 默认值：['black']</h1>
    <h1>lineWidth: 线的宽度(Array) &nbsp; 默认值：["2","2"]</h1>
    <h1>axisFillColor: 每一层的颜色(Array) &nbsp; 默认值：["white", "#ddd"]</h1>
    <h1>axisStrokeColor: 对角线颜色(String) &nbsp; 默认值："gray"</h1>
    <h1>pointsColor: 每个点的颜色(String) &nbsp; 默认值："white"</h1>
    <h1>pointsSize: 每个点的size(Number) &nbsp; 默认值：3</h1>

    <h2>
      展示雷达图
      <span @click="playIn1('refRadarChart1')">入场</span>
      <span @click="playOut('refRadarChart1')">出场</span>
    </h2>
    <radar-chart
      ref="refRadarChart1"
      id="refRadarChart1"
      customClass="c_bar1"
    />

    <h1>angle: 斜角偏移量(Number) &nbsp; 默认值：20</h1>
    <h1>space: 间隙(Number) &nbsp; 默认值：10</h1>
    <h1>colors: 每一个柱体的颜色(Array) &nbsp; 默认值：["",""](不填为黑色)</h1>
    <h1>
      isMin: 数据过小时 时候固定最小值的宽度(Boolean || Number) &nbsp;
      默认值：false(为true时默认占比5%,填写Number时默认占比为Number)
    </h1>
    <h1>
      showText: 是否展示label的文字(Boolean) &nbsp; 默认值：true
      (若不想全部展示在对应数据中增加showLabel字段为false即可)
    </h1>
    <h1>effect: 入场动画特效("leftIn"||"bothIn") &nbsp; 默认值："bothIn"</h1>
    <h1>
      textOffset: label内容展示位置("top"||"bottom") &nbsp; 默认值："bottom"
    </h1>

    <h2>
      展示对比图
      <span @click="playIn2('ratioBarChart')">入场</span>
      <span @click="playOut('ratioBarChart')">出场</span>
    </h2>
    <ratio-bar-chart
      ref="ratioBarChart"
      id="ratioBarChart"
      customClass="c_bar2 c_bar1"
    />
  </div>
</template>
<script>
import * as d3 from "d3";
import radarChart from "@/components/radarChart/index.vue";
import ratioBarChart from "@/components/ratioBarChart/index.vue";
export default {
  data: function () {
    return {
      data1: [
        { label: "潜力", value1: 80, value2: 70 },
        { label: "市场", value1: 95, value2: 85 },
        { label: "主力", value1: 62, value2: 80 },
        { label: "走势", value1: 50, value2: 60 },
        { label: "价值", value1: 70, value2: 65 },
        { label: "投顾", value1: 85, value2: 40 },
      ],
      data2: [
        { label: "涨", value: 3600, showLabel: true }, //showLabel不填默认为false
        { label: "平", value: 60 },
        { label: "平", value: 300, showLabel: true },
        { label: "平", value: 120, showLabel: false },
        { label: "跌", value: 800, showLabel: true },
      ],
    };
  },
  components: { radarChart, ratioBarChart },
  methods: {
    playIn1(refName) {
      this.$refs[refName].RadarChart(this.data1, {
        x: (d) => d.label,
        width: 300,
        height: 400,
        marginTop: 40,
        marginLeft: 80,
        marginRight: 80,
        marginBottom: 40,
        delay: 100,
        margins: { top: 0, left: 0, bottom: 0, right: 0 },
        textColor: "black",
        radius: 100,
        duration: 1000,
        tickNum: 4, //几层多边形
        lineColors: ["red", "green"],
        lineWidth: ["2", "2"],
        axisFillColor: ["white", "#ddd"], //每一层的颜色
        axisStrokeColor: "gray", //对角线的颜色
        pointsColor: "white", //每个点的颜色
        pointsSize: 3, //每个点的size
      });
    },

    playIn2(refName) {
      this.$refs[refName].ratioBarChart(this.data2, {
        x: (d) => d.label,
        width: 600,
        height: 70,
        marginTop: 30,
        marginLeft: 80,
        marginRight: 80,
        marginBottom: 20,
        delay: 500,
        duration: 500,
        angle: 10, //斜角偏移量
        space: 5, //默认间隙
        colors: ["red", "gray", "yellow", "brown", "green"],
        isMin: 0.05, //数据过小时 时候固定最小值的宽度
        showText: true,
        effect: "leftIn",
        textOffset: "top",
      });
    },

    playOut(refName) {
      this.$refs[refName].playOut();
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.holder {
  padding: 20px;
}
h2 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: normal;
  color: #333;
  span {
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    background: #999;
    color: #fff;
    user-select: none;
    margin: 0 3px;
  }
  span:nth-of-type(1) {
    background: #447746;
  }
  span:nth-of-type(2) {
    background: #953e23;
  }
}
h1 {
  font-size: 18px;
  color: #9c27b0;
  margin-bottom: 10px;
}
p {
  margin-bottom: 20px;
  color: #555;
}

.c_bar1 {
  background: #ffd918;
  background: -webkit-linear-gradient(bottom right, #ffd918, #e8882d);
  background: -moz-linear-gradient(bottom right, #ffd918, #e8882d);
  background: linear-gradient(to top left, #ffd918, #e8882d);
  font-size: 14px;
  min-height: 100px;
  display: inline-block;
  margin-bottom: 30px;
}
.box {
  margin: 10% auto;
  width: 600px;
  height: 400px;
  background-color: #ddd;
}

.title {
  font-size: 20px;
}

.label {
  font-size: 13px;
}

.polygon {
  fill-opacity: 0.8;
}
.c_bar2 {
  /deep/ .text {
    font-family: "harmonyMidium";
    font-size: 18px;
  }
}
</style>
